<template>
  <div class="container-fluid">
    <h4 class="text-center my-4">Список студентов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="deputyDeanResearchCup_form.students" showGridlines stripedRows
                 :globalFilterFields="['barcode', 'lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
          </div>
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="barcode" header="Баркод"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.last_name}} {{data.first_name}}
          </template>
        </Column>
        <Column field="phone" header="Телефон"></Column>
        <Column header="ОП">
          <template #body="{data}">
            {{data.education_speciality_code}} {{data.education_speciality_name}}
          </template>
        </Column>

<!--        <Column field="project_theme_direction_name" header="Направление темы проекта"></Column>-->
        <Column field="theme" header="Тема проекта"></Column>
        <Column header="Дата регистрации">
          <template #body="{data}">
            {{ convertGraduateWorkTimestampToDate(data.created_at) }}
          </template>
        </Column>
        <Column header="Этап I">
          <template #body="{data}">
            <div v-if="data.video_file != null">
              <a :href="data.video_file" target="_blank">Видео</a>
            </div>
            <div v-else>
              <p>Нет файла</p>
            </div>
          </template>
        </Column>
        <Column header="Этап II">
          <template #body="{data}">
            <div v-if="data.article_file != null">
              <a :href="data.article_file" target="_blank">Статья</a>
            </div>
            <div v-else>
              <p>Нет файла</p>
            </div>
          </template>
        </Column>
        <Column header="Этап III">
          <template #body="{data}">
            <div v-if="data.presentation_file != null">
              <a :href="data.presentation_file" target="_blank">Презентация</a>
            </div>
            <div v-else>
              <p>Нет файла</p>
            </div>
          </template>
        </Column>
        <Column field="comment" header="Комментарий"></Column>

        <Column header="Действие">
          <template #body="{data}">
            <div v-if="data.status == 0">
              <Button icon="pi pi-check" class="p-button-rounded p-button-success m-1"
                      @click="confirmProject(data.id)"/>
              <Button icon="pi pi-times" class="p-button-rounded p-button-danger m-1"
                      @click="rejectProject(data.id)"/>
            </div>
            <div v-else-if="data.status == 1">
              <p>Потвержден</p>
            </div>
            <div v-else>
              <p>Отказано</p>
            </div>

          </template>
        </Column>


      </DataTable>

      <ConfirmDialog></ConfirmDialog>




    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate";

export default {
  name: 'RegisteredStudentsList',
  data() {
    return {
      loading: true,

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'lastname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'firstname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },

      students: [
        {
          id: 1,
          student_id: 2157,
          barcode: 2112021,
          lastname: 'Иванов',
          firstname: 'Иван',
          project_theme: 'вапвапвап',
          project_theme_direction: 'физика',
          video_file: 'https://www.office.com/',
          status: 0
        }
      ],


    }
  },
  computed: {
    ...mapState('deputyDeanResearchCup', ['deputyDeanResearchCup_form'])
  },

  methods: {

    ...mapActions('deputyDeanResearchCup', ['GET_REGISTERED_STUDENTS','PUT_STUDENT_RESEARCH_CUP_REGISTRATION_STATUS']),
    convertGraduateWorkTimestampToDate,
    confirmProject(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите принять?',
        header: 'Принять',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          let form = {}

          form['id'] = id
          form['status'] = 1


          const res = await this.PUT_STUDENT_RESEARCH_CUP_REGISTRATION_STATUS(form)
          if (res) {
            await this.GET_REGISTERED_STUDENTS()
            this.$message({title: 'Статус успешно обновлен'})
          }
          form = {}
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },

    rejectProject(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите отклонить?',
        header: 'Отклонить',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          let form = {}

          form['id'] = id
          form['status'] = 2


          const res = await this.PUT_STUDENT_RESEARCH_CUP_REGISTRATION_STATUS(form)
          if (res) {
            await this.GET_REGISTERED_STUDENTS()
            this.$message({title: 'Статус успешно обновлен'})
          }
          form = {}
        },
        reject: () => {
          this.$confirm.close();
        },
      });
    },




    // async saveStatus(id, status) {
    //   let form = {}
    //   form['id'] = id
    //   form['status'] = status
    //   const res = await this.PUT_STUDENT_RESEARCH_CUP_REGISTRATION_STATUS(form)
    //   if (res) {
    //     await this.GET_REGISTERED_STUDENTS()
    //     this.$message({title: 'Статус успешно обновлен'})
    //   }
    //   form = {}
    // }

  },
  async mounted() {
    await this.GET_REGISTERED_STUDENTS()

    this.loading = false
  },
}

</script>